import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const AboutUs: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <motion.h1
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-4xl font-bold text-[#8C1D40] mb-8"
      >
        About MCDi
      </motion.h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <AboutSection
          title="Meet MCDi Board"
          description="Get to know the dedicated individuals leading our organization."
          link="/meet-board"
        />
        <AboutSection
          title="Bylaws"
          description="Learn about the rules and regulations governing MCDi."
          link="/bylaws"
        />
        <AboutSection
          title="Minutes"
          description="Access records of our meetings and decisions."
          link="/minutes"
        />
      </div>
    </div>
  );
};

const AboutSection: React.FC<{ title: string; description: string; link: string }> = ({ title, description, link }) => (
  <Link to={link}>
    <motion.div
      whileHover={{ scale: 1.05 }}
      className="bg-white rounded-lg shadow-md p-6 h-full cursor-pointer"
    >
      <h2 className="text-2xl font-semibold text-[#8C1D40] mb-4">{title}</h2>
      <p className="text-gray-700">{description}</p>
    </motion.div>
  </Link>
);

export default AboutUs;
