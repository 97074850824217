import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Calendar from './components/Calendar';
import Minutes from './components/Minutes';
import AboutUs from './components/AboutUs';
import Footer from './components/Footer';
import Bylaws from './components/Bylaws';
import Sponsors from './components/Sponsors';
import MeetBoard from './components/Meet-Board';
/* import ComingSoon from './components/ComingSoon'; */
import JoinMCDi from './components/JoinMCDi';

const App: React.FC = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow px-4 sm:px-6 lg:px-8 py-8">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/minutes" element={<Minutes />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/bylaws" element={<Bylaws />} />
            <Route path="/sponsors" element={<Sponsors />} />
            <Route path="/meet-board" element={<MeetBoard />} />
            <Route path="/joinmcdi" element={<JoinMCDi />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;