import React from 'react';
import { motion } from 'framer-motion';
import { Event } from '../eventData';

interface EventCardProps {
  event: Event & {
    formattedDateRange: string;
  };
}

const EventCard: React.FC<EventCardProps> = ({ event }) => {
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      className="bg-white rounded-lg shadow-md p-4 w-full max-w-xs border border-gray-200"
    >
      <h3 className="font-bold text-lg text-[#6B1631] mb-2">{event.title}</h3>
      <div className="text-sm text-gray-900 mb-2 font-medium whitespace-pre-line">{event.formattedDateRange}</div>
      <div className="text-sm text-gray-900 mb-2">{event.description}</div>
      <div className="text-sm text-gray-900 mb-2">
        <strong className="font-semibold">Address:</strong> {event.address}
      </div>
      <div className="text-sm text-gray-900">
        <strong className="font-semibold">Contact:</strong>{' '}
        <a 
          href={`mailto:${event.email}`} 
          className="text-[#8C1D40] hover:text-[#6B1631] underline font-medium hover:no-underline"
          aria-label={`Email ${event.pointOfContact}`}
        >
          {event.pointOfContact}
        </a>
      </div>
    </motion.div>
  );
};

export default EventCard;