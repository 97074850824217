import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Modal from 'react-modal';

interface CardProps {
  title: string;
  content: string;
  className?: string;
}

const Card: React.FC<CardProps> = ({ title, content, className }) => (
  <motion.div
    className={`bg-white rounded-lg shadow-md p-6 flex flex-col h-full ${className}`}
    whileHover={{ scale: 1.03 }}
    transition={{ duration: 0.2 }}
  >
    <h2 className="text-xl md:text-2xl font-semibold text-[#8C1D40] mb-4">{title}</h2>
    <p className="text-gray-700 flex-grow text-sm md:text-base">{content}</p>
  </motion.div>
);

const JoinButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    className="text-center"
  >
    <button
      onClick={onClick}
      className="inline-block bg-yellow-400 text-[#8C1D40] font-bold py-2 px-4 rounded-full hover:bg-yellow-300 transition-colors duration-200 shadow-md"
    >
      Join MCDi
    </button>
  </motion.div>
);

const JoinMCDi: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    Modal.setAppElement('#root');
  }, []);

  const handleJoinClick = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setIsModalOpen(true);
    }, 500);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const cards = [
    {
      title: "People Who Are Deaf",
      content: "MCDi offers a wide range of programs, services, and resources tailored for Deaf individuals in Missoula and surrounding areas. We're committed to empowering our community and fostering a vibrant Deaf culture."
    },
    {
      title: "People Transitioning into Deaf Culture",
      content: "MCDi welcomes those embracing Deaf culture. We provide support and resources to help you learn about Deaf communication methods, culture, and the rich cultural diversity of the Deaf community."
    },
    {
      title: "Parents of Deaf Children",
      content: "MCDi offers comprehensive support for parents of Deaf children. We provide educational resources, sign language classes, peer support groups, and guidance on Deaf education options to help your child thrive."
    },
    {
      title: "Deaf Parents",
      content: "MCDi celebrates Deaf parents and their unique perspectives. We offer parenting workshops, family-oriented events, and resources to support you in raising confident, well-rounded children in this world."
    },
    {
      title: "Deaf Seniors",
      content: "MCDi values our Deaf seniors. We provide social gatherings, technology workshops, health and wellness programs, and advocacy services to ensure our elder community members stay connected and supported."
    },
    {
      title: "Deaf Students",
      content: "MCDi supports Deaf students at all educational levels. We offer tutoring services, scholarship information, career guidance, and leadership development programs to help you achieve your academic and professional goals."
    },
    {
      title: "Deaf Educators",
      content: "MCDi collaborates with Deaf educators to enhance Deaf education. We provide professional development workshops, networking opportunities, and resources to support your crucial role in shaping the next generation."
    },
    {
      title: "Deaf Employees",
      content: "MCDi advocates for Deaf employees in the workplace. We offer job search assistance, workshops on workplace rights, communication strategy training, and connections to Deaf-friendly employers to support your career growth."
    }
  ];

  const cardsInLastRow = cards.length % 3 === 0 ? 3 : cards.length % 3;

  return (
    <div className="container mx-auto px-4 py-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="space-y-8"
      >
        <h1 className="text-3xl md:text-4xl font-bold text-[#8C1D40] mb-8 text-center">Join MCDi</h1>
        
        <p className="text-center text-gray-600">Both memberships are $5 annually. Choose the type that best fits your situation!</p>

        <div className="bg-gray-100 p-6 rounded-lg shadow-md">
          <h3 className="text-2xl font-bold text-[#8C1D40] mb-4">Membership Types</h3>
          <div className="grid md:grid-cols-2 gap-6 mb-6">
            <div className="bg-white p-4 rounded-md shadow">
              <h4 className="text-xl font-semibold text-[#8C1D40] mb-2">Individual Member</h4>
              <ul className="list-disc list-inside text-gray-700">
                <li>Deaf person residing in Montana</li>
                <li>Within 75-mile radius of Missoula County Courthouse</li>
                <li>18 years or older</li>
                <li>Eligible for all voting privileges at general meetings</li>
                <li>Can initiate and second motions</li>
                <li>Full participation in MCDi's decision-making process</li>
              </ul>
            </div>

            <div className="bg-white p-4 rounded-md shadow">
              <h4 className="text-xl font-semibold text-[#8C1D40] mb-2">Associate Member</h4>
              <ul className="list-disc list-inside text-gray-700">
                <li>Resides outside 75-mile radius of Missoula County Courthouse, OR</li>
                <li>Any non-signing Deaf or non-Deaf person regardless of residency</li>
                <li>18 years or older</li>
                <li>Can participate in discussions on motions</li>
                <li>Cannot initiate or second motions</li>
                <li>No voting rights</li>
              </ul>
            </div>
          </div>
          <JoinButton onClick={handleJoinClick} />
        </div>

        <div className="mb-8">
          <p className="text-lg text-gray-700">
            The Missoula Council of the Deaf, Inc. (MCDi) is the transformative membership and advocacy organization for the Deaf community in Missoula, Montana. Through our network of Deaf members, we coordinate many programs, services, and resources to defend the rights of Deaf Montanans, provide information and support to Deaf individuals of all ages, and build a community that creates a future full of opportunities. We believe in Deaf people because we are Deaf people—from our democratically elected leaders to our diverse membership throughout Missoula and its surroundings.
          </p>
        </div>

        <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 ${cardsInLastRow === 2 ? 'lg:last:grid-cols-2 lg:last:justify-center' : ''}`}>
          {cards.map((card, index) => (
            <Card 
              key={index} 
              title={card.title} 
              content={card.content} 
              className={index >= cards.length - cardsInLastRow ? 'lg:last:col-span-1' : ''}
            />
          ))}
        </div>

        <div className="mt-12 text-center">
          <p className="text-xl md:text-2xl text-[#8C1D40] mb-6">
            Join MCDi today and help shape our direction to become one of the greatest organizations uplifting the Deaf community and achieving the highest quality of life for all Deaf individuals.
          </p>
        </div>

        <JoinButton onClick={handleJoinClick} />

        <Modal
  isOpen={isModalOpen}
  onRequestClose={closeModal}
  contentLabel="Join MCDi Membership Form"
  className="modal"
  overlayClassName="overlay"
>
  <div className="h-full flex flex-col">
    <h2 className="text-2xl font-bold text-[#8C1D40] mb-4">Join MCDi Membership Form</h2>
    <div className="flex-grow overflow-y-auto">
      <iframe
        src="https://www.zeffy.com/embed/ticketing/ec0af9d1-4f07-4156-a6cc-0fa0878b512b"
        width="100%"
        height="100%"
        frameBorder="0"
      ></iframe>
    </div>
    <button
      onClick={closeModal}
      className="mt-4 bg-[#8C1D40] text-white font-bold py-2 px-4 rounded"
    >
      Close
    </button>
  </div>
</Modal>

      </motion.div>
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-[#8C1D40]"></div>
        </div>
      )}
    </div>
  );
};

export default JoinMCDi;
