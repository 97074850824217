export interface Event {
  date: string;
  title: string;
  description: string;
  startTime: string;
  endTime: string;
  pointOfContact: string;
  email: string;
  address: string;
}

export const events: Event[] = [
  {
    date: '2025-01-11',
    title: 'MCDi Members Meeting',
    description: 'Black Roasting Coffee Company',
    startTime: '10:00',
    endTime: '11:00',
    pointOfContact: 'Rita Brandborg',
    email: 'rita.brandborg@deafmissoula.org',
    address: '525 E Spruce St, Missoula, MT 59801'
  }
];