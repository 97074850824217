import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaPlay, FaPause, FaStop } from 'react-icons/fa';

interface BoardMember {
  name: string;
  position: string;
  email: string;
  image: string;
  bio: string;
}

const boardMembers: BoardMember[] = [
  {
    name: "Eliza Kragh",
    position: "President",
    email: "eliza.kragh@deafmissoula.org",
    image: "/headshots/eliza.jpg",
    bio: "Eliza Kragh is a devoted advocate for Deaf rights and a passionate outdoor enthusiast. When she's not paddling through serene lakes or hiking through untamed wilderness with her trusty sidekick Khaja, you can find Eliza pushing the boundaries of inclusivity and accessibility. Her dry wit and introspective nature often catch people off guard, but one thing is clear: Eliza leads by example, inspiring others to stand up for what's right. With a heart full of conviction and a spirit that refuses to be invisible, she's a true champion of Deaf excellence."
  },
  {
    name: "Rita Brandborg",
    position: "Vice President",
    email: "rita.brandborg@deafmissoula.org",
    image: "/headshots/rita.jpg",
    bio: "Meet Rita Brandborg, the proud mom of two tiny tornados (Levi and Josie) who keep her on her toes! When she's not wrangling her mini-me's or teaching Sign 102 at the University of Montana, you can find Rita snapping photos with her trusty camera or reeling in the big ones fly fishing. This Deaf momma is all about spreading joy, love, and a little bit of chaos wherever she goes! With a heart full of laughter and a mind full of stories, Rita is living life to the fullest - and loving every minute of it!"
  },
  {
    name: "Aubz M",
    position: "Secretary",
    email: "aubz.m@deafmissoula.org",
    image: "/headshots/aubz.jpg",
    bio: "Aubz M is a shining star at the local veterinary hospital, where they're paving their way to become a certified vet tech. This animal whisperer's heart beats for creatures great and small, but it also swells with love for self-care and coziness. When they're not snuggling Kanga (their adorable pup), Aubz can be found soaking up wellness vibes or cracking jokes that'll leave you giggling. With a quick wit and thoughtful spirit, this Deaf rockstar is spreading kindness and compassion wherever they go!"
  },
  {
    name: "Kevin Cooley",
    position: "Treasurer",
    email: "kevin.cooley@deafmissoula.org",
    image: "/headshots/kevin.jpg",
    bio: "Kevin Cooley is a force to be reckoned with in the Deaf community. When he's not digging up dirt (literally) as an excavator operator, you can find him reeling in trout or decorating his pad with skulls (because why not?). Halloween? The holiday of all holidays! Kevin loves living life on his own terms and making those around him smile. With a heart of gold and a mind sharp enough to cut through any situation, he's the kind of friend who'll be there for you no matter what."
  },
  {
    name: "Tessa Williams",
    position: "Event Coordinator",
    email: "tessa.williams@deafmissoula.org",
    image: "/headshots/tessa.jpg",
    bio: "Meet Tessa Williams, the sparkplug of the University of Montana Social Worker's program. By day, she's a social work student with a heart of gold; by night, she's a sass-spewing machine who can take down anyone with her quick wit and sharp humor. When she's not advocating for Deaf rights or making her friends laugh, Tessa can be found sipping on a matcha latte with honey (her happy place). Don't mess with this tiny firecracker - she's got love for all, except maybe for those who can't keep up with her sass."
  }
];

const shuffleArray = (array: BoardMember[]) => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

const MeetBoard: React.FC = () => {
  const [selectedMember, setSelectedMember] = useState<BoardMember | null>(null);
  const [shuffledMembers, setShuffledMembers] = useState<BoardMember[]>([]);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const [progress, setProgress] = useState(0);
  const controlsTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setShuffledMembers(shuffleArray(boardMembers));
  }, []);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleStop = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      const progress = (videoRef.current.currentTime / videoRef.current.duration) * 100;
      setProgress(progress);
    }
  };

  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (videoRef.current) {
      const seekTime = (parseFloat(e.target.value) / 100) * videoRef.current.duration;
      videoRef.current.currentTime = seekTime;
    }
  };

  const handleMouseMove = () => {
    setShowControls(true);
    if (controlsTimeoutRef.current) {
      clearTimeout(controlsTimeoutRef.current);
    }
    controlsTimeoutRef.current = setTimeout(() => {
      if (isPlaying) {
        setShowControls(false);
      }
    }, 3000);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-4xl md:text-5xl font-bold text-[#6B1631] mb-8">Meet Our Board</h2>
        
        <section aria-labelledby="board-members-heading">
          <h3 id="board-members-heading" className="sr-only">Board Members Directory</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8" role="list">
            {shuffledMembers.map((member, index) => (
              <BoardMemberCard 
                key={index} 
                member={member} 
                onClick={() => setSelectedMember(member)}
                aria-label={`View details for ${member.name}, ${member.position}`}
              />
            ))}
          </div>
        </section>

        <section aria-labelledby="video-section-heading" className="mt-12">
          <h3 id="video-section-heading">Introduction Video</h3>
          <div 
            className="relative rounded-lg overflow-hidden shadow-lg border-4 border-[#6B1631]"
            onMouseMove={handleMouseMove}
            onMouseLeave={() => isPlaying && setShowControls(false)}
          >
            <video 
              ref={videoRef}
              className="w-full"
              onTimeUpdate={handleTimeUpdate}
              onEnded={() => setIsPlaying(false)}
              aria-label="MCDi Introduction Video"
            >
              <source src="/videos/mcdi-intro.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <AnimatePresence>
              {showControls && (
                <motion.div 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-75 p-2 md:p-4"
                  role="group"
                  aria-label="Video controls"
                >
                  <div className="flex items-center justify-between">
                    <button 
                      onClick={handleStop} 
                      className="text-white hover:text-yellow-300 transition-colors mr-2 p-2"
                      aria-label="Stop video"
                    >
                      <FaStop size={20} />
                    </button>
                    <button 
                      onClick={togglePlay} 
                      className="text-white hover:text-yellow-300 transition-colors mr-2 p-2"
                      aria-label={isPlaying ? "Pause video" : "Play video"}
                    >
                      {isPlaying ? <FaPause size={20} /> : <FaPlay size={20} />}
                    </button>
                    <input 
                      type="range"
                      min={0}
                      max={100}
                      value={progress}
                      onChange={handleSeek}
                      className="w-full mx-2 accent-yellow-300"
                      aria-label="Video progress"
                      aria-valuemin={0}
                      aria-valuemax={100}
                      aria-valuenow={progress}
                    />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </section>
      </motion.div>

      <AnimatePresence>
        {selectedMember && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50"
            onClick={() => setSelectedMember(null)}
            role="dialog"
            aria-modal="true"
            aria-labelledby="member-modal-title"
          >
            <motion.div
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -50, opacity: 0 }}
              className="bg-white rounded-lg p-4 md:p-8 max-w-md w-full max-h-[90vh] overflow-y-auto"
              onClick={(e) => e.stopPropagation()}
            >
              <h3 id="member-modal-title" className="text-2xl md:text-3xl font-bold text-[#6B1631] mb-4">
                {selectedMember.name}
              </h3>
              <h4 className="text-xl md:text-2xl font-semibold text-[#8C1D40] mb-2">
                {selectedMember.position}
              </h4>
              <p className="text-base md:text-lg text-gray-900 mb-4 leading-relaxed">
                {selectedMember.bio}
              </p>
              <a 
                href={`mailto:${selectedMember.email}`} 
                className="inline-block text-[#6B1631] hover:text-[#8C1D40] font-semibold hover:underline text-base md:text-lg transition-colors"
                aria-label={`Send email to ${selectedMember.name}`}
              >
                {selectedMember.email}
              </a>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const BoardMemberCard: React.FC<{ 
  member: BoardMember; 
  onClick: () => void;
  'aria-label'?: string;
}> = ({ member, onClick, 'aria-label': ariaLabel }) => (
  <motion.div
    className="bg-white rounded-lg shadow-lg p-4 md:p-6 flex flex-col items-center cursor-pointer border-2 border-transparent hover:border-[#6B1631] transition-colors"
    whileHover={{ scale: 1.03 }}
    transition={{ duration: 0.2 }}
    onClick={onClick}
    role="listitem"
    aria-label={ariaLabel}
  >
    <h4 className="text-2xl md:text-3xl font-bold text-[#6B1631] mb-2 md:mb-4">
      {member.name}
    </h4>
    <img 
      src={member.image} 
      alt={`${member.name}, ${member.position}`} 
      className="w-32 h-32 md:w-48 md:h-48 object-cover rounded-full mb-2 md:mb-4 border-4 border-[#8C1D40]" 
    />
    <p className="text-lg md:text-xl font-semibold text-gray-900">
      {member.position}
    </p>
  </motion.div>
);

export default MeetBoard;