import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface SponsorCardProps {
  name: string;
  logoPath: string;
  description: string;
  onClick: () => void;
  websiteUrl?: string;
  facebookUrl?: string;
  instagramUrl?: string;
}

const SponsorCard: React.FC<SponsorCardProps> = ({ name, logoPath, onClick, websiteUrl, facebookUrl, instagramUrl }) => (
  <motion.div
    className="bg-white rounded-lg shadow-md p-4 sm:p-6 flex flex-col items-center h-full cursor-pointer"
    whileHover={{ scale: 1.03 }}
    transition={{ duration: 0.2 }}
    onClick={onClick}
  >
    <h3 className="text-xl sm:text-2xl font-semibold text-[#8C1D40] mb-2 sm:mb-4 text-center">{name}</h3>
    <div className="w-full h-32 sm:h-48 flex items-center justify-center mb-4">
      <img src={logoPath} alt={`${name} logo`} className="max-w-full max-h-full object-contain" />
    </div>
    <div className="flex justify-center space-x-4 mt-auto">
      {websiteUrl && (
        <a href={websiteUrl} target="_blank" rel="noopener noreferrer" onClick={(e) => e.stopPropagation()}>
          <img src="/logos/website-logo.png" alt="Website" className="w-6 h-6" />
        </a>
      )}
      {facebookUrl && (
        <a href={facebookUrl} target="_blank" rel="noopener noreferrer" onClick={(e) => e.stopPropagation()}>
          <img src="/logos/facebook-logo.png" alt="Facebook" className="w-6 h-6" />
        </a>
      )}
      {instagramUrl && (
        <a href={instagramUrl} target="_blank" rel="noopener noreferrer" onClick={(e) => e.stopPropagation()}>
          <img src="/logos/instagram-logo.png" alt="Instagram" className="w-6 h-6" />
        </a>
      )}
    </div>
  </motion.div>
);

interface PopupProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  websiteUrl?: string;
  facebookUrl?: string;
  instagramUrl?: string;
}

const Popup: React.FC<PopupProps> = ({ isOpen, onClose, children, websiteUrl, facebookUrl, instagramUrl }) => (
  <AnimatePresence>
    {isOpen && (
      <motion.div
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={onClose}
      >
        <motion.div
          className="bg-white rounded-lg p-4 sm:p-6 w-full max-w-md"
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
          <div className="flex justify-center space-x-4 mt-4 mb-4">
            {websiteUrl && (
              <a href={websiteUrl} target="_blank" rel="noopener noreferrer">
                <img src="/logos/website-logo.png" alt="Website" className="w-6 h-6" />
              </a>
            )}
            {facebookUrl && (
              <a href={facebookUrl} target="_blank" rel="noopener noreferrer">
                <img src="/logos/facebook-logo.png" alt="Facebook" className="w-6 h-6" />
              </a>
            )}
            {instagramUrl && (
              <a href={instagramUrl} target="_blank" rel="noopener noreferrer">
                <img src="/logos/instagram-logo.png" alt="Instagram" className="w-6 h-6" />
              </a>
            )}
          </div>
          <button className="mt-4 bg-[#8C1D40] text-white px-4 py-2 rounded w-full sm:w-auto" onClick={onClose}>Close</button>
        </motion.div>
      </motion.div>
    )}
  </AnimatePresence>
);

interface SponsorInfo {
  description: string;
  logoPath: string;
  websiteUrl?: string;
  facebookUrl?: string;
  instagramUrl?: string;
}

const Sponsors: React.FC = () => {
  const [selectedSponsor, setSelectedSponsor] = useState<string | null>(null);

  const sponsorInfo: Record<string, SponsorInfo> = {
    "Drum Coffee": {
      description: "Drum Coffee partnered with MCDi to provide ASL lessons on coffee-related signology, enhancing their ability to serve Deaf community members. This initiative promotes inclusivity and improves communication in their bustling cafe environment.",
      logoPath: "/sponsors/drum-coffee-missoula.png",
      websiteUrl: "https://drumcoffeeroasting.com/",
      facebookUrl: "https://m.facebook.com/drumcoffeemt",
      instagramUrl: "https://www.instagram.com/drumcoffee/"
    },
    "Imagine Nation Brewing": {
      description: "Imagine Nation Brewing hosted a vibrant fundraising event, donating proceeds to MCDi. Their generous support not only raised funds but also raised awareness about the Deaf community, making a significant impact on our mission.",
      logoPath: "/sponsors/imagine-nation-brewing.png",
      websiteUrl: "https://imaginenationbrewing.com/",
      facebookUrl: "https://m.facebook.com/ImagineNationBrewing",
      instagramUrl: "https://www.instagram.com/imaginenationbrewingco"
    },
    "GILD Brewing": {
      description: "GILD Brewing organized a community-focused fundraiser for MCDi, combining craft beer tasting with Deaf culture education. Their event not only raised funds but also fostered a deeper understanding of Deaf culture, making a significant impact on our mission.",
      logoPath: "/sponsors/gild-brewing.png",
      websiteUrl: "https://www.gildbrewing.com/",
      facebookUrl: "https://m.facebook.com/gildbrewing/",
      instagramUrl: "https://www.instagram.com/gildbrewing/"
    },
  };  

  return (
    <div className="container mx-auto px-4 py-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-3xl sm:text-4xl font-bold text-[#8C1D40] mb-6 sm:mb-8 text-center">Our Sponsors</h2>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-8">
          {Object.entries(sponsorInfo).map(([name, info]) => (
            <SponsorCard 
              key={name}
              name={name} 
              logoPath={info.logoPath}
              description={info.description}
              onClick={() => setSelectedSponsor(name)}
              websiteUrl={info.websiteUrl}
              facebookUrl={info.facebookUrl}
              instagramUrl={info.instagramUrl}
            />
          ))}
        </div>
      </motion.div>

      <Popup 
        isOpen={!!selectedSponsor} 
        onClose={() => setSelectedSponsor(null)}
        websiteUrl={selectedSponsor ? sponsorInfo[selectedSponsor].websiteUrl : undefined}
        facebookUrl={selectedSponsor ? sponsorInfo[selectedSponsor].facebookUrl : undefined}
        instagramUrl={selectedSponsor ? sponsorInfo[selectedSponsor].instagramUrl : undefined}
      >
        {selectedSponsor && (
          <>
            <h4 className="text-xl sm:text-2xl font-bold mb-4">{selectedSponsor}</h4>
            <p className="text-sm sm:text-base">{sponsorInfo[selectedSponsor].description}</p>
          </>
        )}
      </Popup>
    </div>
  );
};

export default Sponsors;