import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import ImageCarousel from './ImageCarousel';
import EventCard from './EventCard';
import { parseISO, startOfDay, isSameDay, isAfter } from 'date-fns';
import { events, Event } from '../eventData';
import { formatDateRange } from './Calendar';

const isEventUpcoming = (eventDate: string) => {
  const today = startOfDay(new Date());
  const parsedEventDate = startOfDay(parseISO(eventDate));
  return isSameDay(parsedEventDate, today) || isAfter(parsedEventDate, today);
};

const Home: React.FC = () => {
  const [upcomingEvents, setUpcomingEvents] = useState<Event[]>([]);

  useEffect(() => {
    const filteredEvents = events
      .filter(event => isEventUpcoming(event.date))
      .sort((a, b) => parseISO(a.date).getTime() - parseISO(b.date).getTime())
      .slice(0, 6);
    setUpcomingEvents(filteredEvents);
  }, []);

  return (
    <div className="container mx-auto px-4 py-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="space-y-8"
      >
        <h1 className="text-3xl md:text-4xl font-bold text-[#6B1631] mb-8 text-center">Welcome to MCDi</h1>
        
        <ImageCarousel />
        <div className="flex flex-col lg:flex-row gap-8">
          <div className="lg:w-1/2 flex flex-col gap-8">
            <InfoCard
              title="Empowering Deaf Culture and Community"
              content="We're proud to be the Missoula Council of the Deaf, Inc. (MCDi) in the heart of Missoula, Montana. Our organization is dedicated to promoting Deaf culture, community, and systematic change in our beautiful state."
            />
            <InfoCard
              title="Deafhood: A Shining Example of Diversity"
              content="Just as Montana's vast landscapes showcase breathtaking vistas, our Deaf community brings unique perspectives and experiences to the table. We're proud to celebrate Deaf culture, language, and traditions that enrich our human kaleidoscope."
            />
          </div>
          <div className="lg:w-1/2">
            <h2 className="text-2xl font-semibold text-[#6B1631] mb-4">Upcoming Events</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {upcomingEvents.map((event, index) => (
                <EventCard 
                  key={index} 
                  event={{
                    ...event,
                    formattedDateRange: formatDateRange(event.date, event.startTime, event.date, event.endTime)
                  }}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <InfoCard
            title="Join Us: Support Change in Montana"
            content="As a parent or ally, you can make a difference by joining MCDi. Together, we'll work toward creating a more inclusive environment for all Montanans, regardless of communication style. Your support will help shape the future of Deaf education, employment, and accessibility in our great state."
          />
          <InfoCard
            title="Get Involved: Explore, Protect, and Give Back"
            content="MCDi is dedicated to protecting and preserving Deaf culture and rights. Join us for events, workshops, and advocacy opportunities that will inspire you to make a difference."
          />
          <InfoCard
            title="Join the Journey: Together We Can Make a Difference"
            content="As we explore, protect, and give back to our community, remember that every individual brings unique strengths and perspectives. Let's work together to create a brighter future for all Montanans, where Deaf culture is celebrated and valued."
          />
        </div>
      </motion.div>
    </div>
  );
};

const InfoCard: React.FC<{ title: string; content: string }> = ({ title, content }) => (
  <motion.div
    className="bg-white rounded-lg shadow-md p-6 flex flex-col h-full"
    whileHover={{ scale: 1.03 }}
    transition={{ duration: 0.2 }}
  >
    <h2 className="text-xl md:text-2xl font-semibold text-[#6B1631] mb-4">{title}</h2>
    <p className="text-gray-900 flex-grow text-sm md:text-base leading-relaxed">{content}</p>
  </motion.div>
);

export default Home;