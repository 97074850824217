import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    honeypot: '',
  });
  const [status, setStatus] = useState('');
  const [errors, setErrors] = useState<Partial<typeof formData>>({});
  const [lastSubmissionTime, setLastSubmissionTime] = useState(0);

  useEffect(() => {
    const storedTime = localStorage.getItem('lastSubmissionTime');
    if (storedTime) {
      setLastSubmissionTime(parseInt(storedTime, 10));
    }
  }, []);

  const validateForm = (data: typeof formData) => {
    const errors: Partial<typeof formData> = {};
    if (!data.name.trim()) errors.name = "Name is required";
    if (!data.email.trim()) errors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(data.email)) errors.email = "Email is invalid";
    if (!data.subject.trim()) errors.subject = "Subject is required";
    if (!data.message.trim()) errors.message = "Message is required";
    return errors;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setStatus('Please correct the errors in the form.');
      return;
    }

    if (formData.honeypot) {
      setStatus('Form submission rejected.');
      return;
    }

    const currentTime = Date.now();
    if (currentTime - lastSubmissionTime < 60000) {
      setStatus('Please wait a moment before submitting again.');
      return;
    }

    setStatus('Sending...');

    try {
      const response = await fetch('/api/sendEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const data = await response.json();
        if (response.ok) {
          setStatus('Message sent successfully!');
          setFormData({ name: '', email: '', subject: '', message: '', honeypot: '' });
          setLastSubmissionTime(currentTime);
          localStorage.setItem('lastSubmissionTime', currentTime.toString());
        } else {
          setStatus(`Failed to send message: ${data.error || 'Unknown error'}`);
        }
      } else {
        setStatus('Received non-JSON response from server');
      }
    } catch (error: unknown) {
      console.error('Error sending message:', error);
      setStatus(`An error occurred: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
  };

  return (
    <footer className="bg-[#8C1D40] text-white py-8" role="contentinfo">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h3 className="text-xl font-bold mb-4">MCDi</h3>
            <p className="text-sm">Missoula Council of the Deaf, Inc.</p>
            <p className="text-sm mt-2">© MCDi {new Date().getFullYear()}</p>
            <a 
              href="https://www.facebook.com/profile.php?id=61564416121195" 
              target="_blank" 
              rel="noopener noreferrer"
              className="inline-block mt-4 transition-transform duration-300 ease-in-out transform hover:scale-110"
              aria-label="Visit MCDi Facebook Page"
            >
              <img 
                src="/logos/facebook-logo.png" 
                alt="MCDi Facebook Page" 
                className="w-10 h-10 rounded-full shadow-lg hover:shadow-xl"
                style={{
                  filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1))',
                }}
              />
            </a>
          </div>
          <nav className="w-full md:w-1/3 mb-6 md:mb-0" aria-label="Footer Navigation">
            <h3 className="text-xl font-bold mb-4">Quick Links</h3>
            <ul className="text-sm grid grid-cols-2 md:grid-cols-1 gap-2">
              <li><Link to="/" className="hover:text-yellow-200 transition-colors duration-200">Home</Link></li>
              <li><Link to="/calendar" className="hover:text-yellow-200 transition-colors duration-200">Calendar</Link></li>
              <li><Link to="/bylaws" className="hover:text-yellow-200 transition-colors duration-200">Bylaws</Link></li>
              <li><Link to="/sponsors" className="hover:text-yellow-200 transition-colors duration-200">Sponsors</Link></li>
              <li><Link to="/meet-board" className="hover:text-yellow-200 transition-colors duration-200">Meet MCDi Board</Link></li>
            </ul>
          </nav>
          <div className="w-full md:w-1/3">
            <h3 className="text-xl font-bold mb-4" id="contact-form">Contact Us</h3>
            <form 
              className="text-sm" 
              onSubmit={handleSubmit} 
              aria-labelledby="contact-form"
            >
              <div className="mb-2">
                <label className="sr-only" htmlFor="name">Name</label>
                <input
                  id="name"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Name"
                  className={`w-full p-2 text-black rounded ${errors.name ? 'border-red-500' : ''}`}
                  required
                  aria-required="true"
                  aria-invalid={errors.name ? "true" : "false"}
                  aria-describedby={errors.name ? "name-error" : undefined}
                />
                {errors.name && <p id="name-error" className="text-red-500 text-xs mt-1" role="alert">{errors.name}</p>}
              </div>
              <div className="mb-2">
                <label className="sr-only" htmlFor="email">Email</label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Your Email Address"
                  className={`w-full p-2 text-black rounded ${errors.email ? 'border-red-500' : ''}`}
                  required
                  aria-required="true"
                  aria-invalid={errors.email ? "true" : "false"}
                  aria-describedby={errors.email ? "email-error" : undefined}
                />
                {errors.email && <p id="email-error" className="text-red-500 text-xs mt-1" role="alert">{errors.email}</p>}
              </div>
              <div className="mb-2">
                <label className="sr-only" htmlFor="subject">Subject</label>
                <input
                  id="subject"
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  placeholder="Subject"
                  className={`w-full p-2 text-black rounded ${errors.subject ? 'border-red-500' : ''}`}
                  required
                  aria-required="true"
                  aria-invalid={errors.subject ? "true" : "false"}
                  aria-describedby={errors.subject ? "subject-error" : undefined}
                />
                {errors.subject && <p id="subject-error" className="text-red-500 text-xs mt-1" role="alert">{errors.subject}</p>}
              </div>
              <div className="mb-2">
                <label className="sr-only" htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Message"
                  rows={3}
                  className={`w-full p-2 text-black rounded ${errors.message ? 'border-red-500' : ''}`}
                  required
                  aria-required="true"
                  aria-invalid={errors.message ? "true" : "false"}
                  aria-describedby={errors.message ? "message-error" : undefined}
                ></textarea>
                {errors.message && <p id="message-error" className="text-red-500 text-xs mt-1" role="alert">{errors.message}</p>}
              </div>
              <input
                type="text"
                name="honeypot"
                value={formData.honeypot}
                onChange={handleChange}
                style={{display: 'none'}}
                tabIndex={-1}
                autoComplete="off"
                aria-hidden="true"
              />
              <button
                type="submit"
                className="bg-yellow-400 text-[#8C1D40] font-bold py-2 px-4 rounded hover:bg-yellow-300 transition-colors duration-200"
                aria-label="Send message"
              >
                Send
              </button>
              {status && <p className="mt-2 text-sm" role="status" aria-live="polite">{status}</p>}
            </form>
          </div>
        </div>
        <div className="w-full text-center mt-8 text-sm">
          <p>
            Designed by{' '}
            <a
              href="https://www.deafgain.org"
              target="_blank"
              rel="noopener noreferrer"
              className="text-yellow-200 hover:text-yellow-400 transition-colors duration-200"
              aria-label="Visit DeafGain LLC website (opens in new tab)"
            >
              DeafGain LLC
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;