import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const Bylaws: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768); // Adjust this breakpoint as needed
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return (
    <div className="container mx-auto px-4 py-8">
      <motion.h1
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-4xl font-bold text-[#8C1D40] mb-8"
      >
        MCDi Bylaws
      </motion.h1>
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="bg-white rounded-lg shadow-lg p-4"
      >
        {isMobile ? (
          <div>
            <p className="mb-4">Click the link below to view the MCDi Bylaws PDF:</p>
            <a
              href="/MCD-Inc._AOI_Bylaws.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-[#8C1D40] text-white font-bold py-2 px-4 rounded hover:bg-[#6B1631] transition duration-300"
            >
              View Bylaws PDF
            </a>
          </div>
        ) : (
          <div className="w-full" style={{ height: '800px' }}>
            <iframe
              src="/MCD-Inc._AOI_Bylaws.pdf#view=FitH"
              className="w-full h-full rounded-md"
              title="MCDi Bylaws"
            />
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default Bylaws;
