import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Modal from 'react-modal';

interface NavItemProps {
  href: string;
  children: React.ReactNode;
  onClick?: () => void;
}

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDonateModalOpen, setIsDonateModalOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleDonateClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDonateModalOpen(true);
  };

  const closeDonateModal = () => {
    setIsDonateModalOpen(false);
    setIsMenuOpen(false);
  };

  return (
    <header className="bg-[#6B1631] text-white shadow-lg" role="banner">
      <div className="container mx-auto px-4 py-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <img src="/mcdi-logo-small.png" alt="MCDi Logo" className="w-12 h-12 md:w-20 md:h-20 mr-2 md:mr-4" />
            <h1 className="text-lg md:text-2xl font-bold text-white">Missoula Council of the Deaf, Inc</h1>
          </div>
          <nav className="hidden md:block" role="navigation" aria-label="Main navigation">
            <ul className="flex space-x-6 items-center">
              <li><NavItem href="/">Home</NavItem></li>
              <li><AboutDropdown /></li>
              <li><NavItem href="/calendar">Calendar</NavItem></li>
              <li><NavItem href="/joinmcdi">Join MCDi</NavItem></li>
              <li><DonateButton onClick={handleDonateClick} /></li>
            </ul>
          </nav>
          <button 
            className="md:hidden text-white hover:text-yellow-100"
            onClick={toggleMenu}
            aria-label="Toggle navigation menu"
            aria-expanded={isMenuOpen}
            aria-controls="mobile-menu"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>
        </div>
      </div>
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            id="mobile-menu"
            role="navigation"
            aria-label="Mobile navigation"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="md:hidden bg-[#6B1631] px-4 py-2"
          >
            <ul className="space-y-2">
              <li><NavItem href="/" onClick={() => setIsMenuOpen(false)}>Home</NavItem></li>
              <li><NavItem href="/about" onClick={() => setIsMenuOpen(false)}>About MCDi</NavItem></li>
              <li><NavItem href="/calendar" onClick={() => setIsMenuOpen(false)}>Calendar</NavItem></li>
              <li><NavItem href="/joinmcdi" onClick={() => setIsMenuOpen(false)}>Join MCDi</NavItem></li>
              <li><DonateButton onClick={handleDonateClick} /></li>
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
      <Modal
        isOpen={isDonateModalOpen}
        onRequestClose={closeDonateModal}
        contentLabel="Donate to MCDi"
        className="modal"
        overlayClassName="overlay"
        aria={{
          labelledby: "donate-modal-title",
          describedby: "donate-modal-description"
        }}
      >
        <div className="h-full flex flex-col bg-white">
          <h2 id="donate-modal-title" className="text-2xl font-bold text-[#4A0D22] mb-4">Donate to MCDi</h2>
          <div id="donate-modal-description" className="flex-grow overflow-y-auto">
            <iframe
              title="Donation form"
              src="https://www.zeffy.com/embed/donation-form/1cbff18e-c057-4fa6-8276-a993037de0bb"
              width="100%"
              height="100%"
              frameBorder="0"
            ></iframe>
          </div>
          <button
            onClick={closeDonateModal}
            className="mt-4 bg-[#4A0D22] text-white font-bold py-2 px-4 rounded hover:bg-[#6B1631] transition-colors duration-200"
            aria-label="Close donation modal"
          >
            Close
          </button>
        </div>
      </Modal>
    </header>
  );
};

const NavItem: React.FC<NavItemProps> = ({ href, children, onClick }) => (
  <Link to={href} className="block text-white hover:text-yellow-100 transition-colors duration-200" onClick={onClick}>
    <motion.span whileHover={{ scale: 1.05 }} className="inline-block">
      {children}
    </motion.span>
  </Link>
);

const AboutDropdown: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div 
      className="relative" 
      onMouseEnter={() => setIsOpen(true)} 
      onMouseLeave={() => setIsOpen(false)}
      role="navigation"
      aria-label="About MCDi dropdown"
    >
      <Link 
        to="/about" 
        className="text-white hover:text-yellow-100 transition-colors duration-200"
        aria-expanded={isOpen}
        aria-controls="about-dropdown"
      >
        <motion.span whileHover={{ scale: 1.05 }} className="inline-block">
          About MCDi
        </motion.span>
      </Link>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            id="about-dropdown"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="absolute left-0 mt-2 w-48 bg-[#4A0D22] rounded-md shadow-lg z-10"
          >
            <div className="py-1" role="menu">
              <DropdownItem href="/meet-board">Meet MCDi Board</DropdownItem>
              <DropdownItem href="/bylaws">Bylaws</DropdownItem>
              <DropdownItem href="/minutes">Minutes</DropdownItem>
              <DropdownItem href="/sponsors">Sponsors</DropdownItem>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const DropdownItem: React.FC<NavItemProps> = ({ href, children }) => (
  <Link 
    to={href} 
    className="block px-4 py-2 text-white hover:bg-[#6B1631] hover:text-yellow-100 transition-colors duration-200"
    role="menuitem"
  >
    <motion.span whileHover={{ x: 5 }} className="inline-block">
      {children}
    </motion.span>
  </Link>
);

const DonateButton: React.FC<{ onClick: (e: React.MouseEvent) => void }> = ({ onClick }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <button
      onClick={onClick}
      className="inline-block bg-yellow-500 text-[#4A0D22] font-bold py-2 px-4 rounded-full hover:bg-yellow-400 transition-colors duration-200 shadow-md"
      aria-label="Open donation form"
    >
      Donate
    </button>
  </motion.div>
);

export default Header;